import router from '@/router/index'
import { storage } from '@/utils/common.js'

function backPrivious() {
    router.go(-1)
    storage.removeStroage('fetchDataPage')
    storage.removeStroage('fetchDataSize')
    let path = window.location.pathname
    switch (path) {
        // 产品库搜索筛选
        case '/ProductLibrary':
            storage.removeStroage('ProductLibrarySearchValue');
            storage.removeStroage('ProductLibraryOpenSearchFlag');
            storage.removeStroage('handleScanValue');
            break;
        //财务统计搜索筛选
        case '/FinancialStatistics':
            storage.removeStroage('FinancialStatisticsDrawerForm');
            break;
        //库存记录搜索筛选
        case '/InventoryRecords':
            storage.removeStroage('InventoryRecordsDrawerForm');
            break;
        //订单统计
        case '/OrderStatistics':
            storage.removeStroage('OrderStatisticsData');
            break;
        //企业内部职位列表
        case '/EnterpriseInternalRecruitment':
            storage.removeStroage('EnterpriseInternalRecruitmentInfo');
            break
        //权限批量管理
        case '/PermissionBatchManagement':
            storage.setStorage('keepmultipleSelection', [])
            break;
        //企业相关产品列表
        case '/MoreProductList':
            // storage.removeStroage('EnterpriseRelatedId')
            break;
        //搜索商品
        case '/search':
            storage.removeStroage('keepProductIdSimilarityList')
            break;
        //企业相关产品列表
        case '/JoinEnterpriseList':
            storage.removeStroage('JoinEnterpriseSearchValue')
            break;
        //颜色搜索
        case '/Coloursearch':
            storage.removeStroage('params')
            storage.removeStroage('picker_color')
            storage.removeStroage('csState')
            storage.removeStroage('ColorPalette_color')
            break;
    }
}

// 分页保留
export const PaginationRetention = {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,//总页数
            currentPage: 1,//当前页数
            layout: 'sizes, prev, pager, next,total,jumper',
            pagingSize: 10,//分页保留条数
            paging: 1,//分页保留
        }
    },
    created() {
        if (this.$route.path == '/search' || this.$route.path == '/AssociatedOrder') {
            this.$removeStorage('fetchDataSize')
            this.$removeStorage('fetchDataPage')
        } else {
            this.size = this.$getStorage('fetchDataSize') ? this.$getStorage('fetchDataSize') : this.size
            this.page = this.$getStorage('fetchDataPage') ? this.$getStorage('fetchDataPage') : this.page
        }
        this.fetchData()
    },
    methods: {
        fetchData(e) {
            let page = e ? e.pageNum : (this.$getStorage('fetchDataPage') ? this.$getStorage('fetchDataPage') : this.page),
                size = e ? e.limit : (this.$getStorage('fetchDataSize') ? this.$getStorage('fetchDataSize') : this.size);
            if (e && e.orginLimit && (e.limit != e.orginLimit)) {
                page = 1
            }
            this.$setStorage('fetchDataPage', page)
            this.$setStorage('fetchDataSize', size)
            this.paging = page;//分页保留页数
            this.pagingSize = size;//分页保留分页条数
            this.page = page;
            this.size = size;
            // 为了解决一进页面就请求数据问题，但这样导致分页失败
            // this.$route.path != '/AddOrder' ? this.getDate() : ''
            let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
            this.$route.path == '/SupplierInfo' && params.type != 'product' ? '' : this.getDate()
        },
        backPrivious() {
            backPrivious()
        }
    }
}

/**
 * PaginationRetention 和 PaginationRetention1的区别
 * PaginationRetention会在create生命周期中执行接口调用操作，调用一次getDate
 * PaginationRetention1中create生命周期不执行接口调用操作
 */

// 分页保留
// InventoryRecords OrderList OrderStatistics使用，内部有created生命周期，生命周期会合并
export const PaginationRetention1 = {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,//总页数
            currentPage: 1,//当前页数
            layout: 'sizes, prev, pager, next,total,jumper',
            pagingSize: 10,//分页保留条数
            paging: 1,//分页保留
        }
    },
    created() {
        this.size = this.$getStorage('fetchDataSize') ? this.$getStorage('fetchDataSize') : this.size
        this.page = this.$getStorage('fetchDataPage') ? this.$getStorage('fetchDataPage') : this.page
    },
    methods: {
        fetchData(e) {
            let page = e ? e.pageNum : (this.$getStorage('fetchDataPage') ? this.$getStorage('fetchDataPage') : this.page),
                size = e ? e.limit : (this.$getStorage('fetchDataSize') ? this.$getStorage('fetchDataSize') : this.size);
            if (e && e.orginLimit && (e.limit != e.orginLimit)) {
                page = 1
            }
            this.$setStorage('fetchDataPage', page)
            this.$setStorage('fetchDataSize', size)
            this.paging = page;//分页保留页数
            this.pagingSize = size;//分页保留分页条数
            this.getDate()
        },
        backPrivious() {
            backPrivious()
        }
    }
}