export const ChineseCharactersInputMixin = {
    data() {
        return {
            searchValue: '',
        }
    },
    methods: {
        // 用于输入框数据被删除时触发获取原始列表
        HandleInput(data) {
            if (data == '') {
                this.searchValue = data
                this.isSearch = false
                this.getDate()
            }
        },
        // change获取输入框内容
        getValue(data) {
            this.searchValue = data.value
        },
        // 回车触发搜索
        enterSearch() {
            this.search()
        }
    }

}