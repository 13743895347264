<template>
    <div class="EmployeeList">
        <el-page-header @back="backPrivious" content="员工列表"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <!-- 搜索 -->
                <div class="f-c-b feature">
                    <div class="search_input">
                        <input type="text" placeholder="请输入员工名称搜索" v-model.trim="searchValue" @keyup.enter="enterSearch"
                            maxlength="10" />
                        <el-button class="search_icon" @click="search" icon="el-icon-search"></el-button>
                    </div>
                    <div class="Btnbox f-c-c">
                        <div class="addBtn f-c-c" @click="inviteDialogShow = true" v-show="isShowAddBtn == 'W'"><i
                                class="el-icon-circle-plus-outline"></i>添加</div>
                        <div @click="drawer = true" class="screenBtn"
                            :style="{ display: isShowScreenBtn === 'D' ? 'none' : 'block' }">
                            <i class="el-icon-finished"></i>
                            <span>筛选</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="customerTotal">员工总数：{{ total }} 个</div> -->
                <!-- 列表 -->
                <div class="list f-w" v-if="UserList && UserList.length">
                    <router-link :to="{
                        name: 'EmployeeInfo', query: {
                            key: $UrlEncode.encode(JSON.stringify({
                                id: item.recordeId, type: 'employeeInfo'
                            }))
                        }
                    }" class="item f-c" v-for="(item, index) of UserList" :key="index"
                        :style="{ marginRight: (index % 2 == 0) ? '20px' : '0' }">
                        <img class="userAvatarUrl" :src="item.userAvatarUrl" alt="">
                        <div class="f-cl-b textBox">
                            <span>{{ item.ecardName }}</span>
                            <span>联系方式：{{ item.ecardPhone }}</span>
                            <span>所属部门：{{ item.ecardNote ? item.ecardNote : '未设置' }}</span>
                        </div>
                        <!-- dcPermissionFlag -->
                        <div class="BusinessOwner" v-if="item.dcPermissionFlag == 'Y'">企业所有者</div>
                        <!-- <img class="BusinessOwner" v-if="item.dcPermissionFlag == 'Y'"
                            src="@/assets/supply/BusinessOwner.png" alt=""> -->
                    </router-link>
                </div>
                <el-empty v-else :description="searchValue ? '搜索不到该员工！' : '快去添加员工吧！'"></el-empty>
            </div>
        </div>
        <!-- <div class="input-box f-c mb-10">
            <ChineseCharactersInput :modelValue="searchValue" :PrefixIcon="'el-icon-search'" @getValue="getValue"
                :type="'EmployeeList'" :placeholderValue="'请输入员工名称搜索'" :isShowBtn="true" @HandleInput="HandleInput"
                @enterSearch="enterSearch">
            </ChineseCharactersInput>
            <el-button v-show="isShowAddBtn == 'W'" class="add-Employee f-c-c" @click="inviteDialogShow = true">
                <div class="f-c-c">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加</span>
                </div>
            </el-button>
            <el-button plain @click="drawer = true" :style="{ display: isShowScreenBtn === 'D' ? 'none' : 'block' }"
                class="screen f-c-c">
                <i class="el-icon-finished"></i>
                <span>筛选</span>
            </el-button>
        </div> -->
        <!--   -->
        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
        <PowerDrawer :drawer="drawer" @submitForm="submitForm" @resetForm="resetForm" @closeDrawer="closeDrawer"
            :companyInfoNoAvailable="true">
        </PowerDrawer>
        <InviteEmployee :inviteDialogShow="inviteDialogShow" @closeInviteDialog="closeInviteDialog"></InviteEmployee>
    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { ChineseCharactersInputMixin } from '@/mixins/ChineseCharactersInputMixin'

export default {
    name: 'EmployeeList',
    mixins: [PaginationRetention, ChineseCharactersInputMixin],
    components: {
        InviteEmployee: () => import(/* webpackChunkName: "InviteEmployee" */ '@/pages/SupplyChainManagement/personnelManagement/components/InviteEmployee.vue'),
        PowerDrawer: () => import(/* webpackChunkName: "PowerDrawer" */ '@/pages/SupplyChainManagement/personnelManagement/components/PowerDrawer.vue'),
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            UserList: [],//审批列表
            drawer: false,
            direction: 'rtl',
            userPermission: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
            isShowAddBtn: this.$getStorage('enterpriseStateInfo').userPermission.p_m,//是否显示添加按钮
            isShowScreenBtn: this.$getStorage('enterpriseStateInfo').userPermission.a_m,//是否显示赛选盒子
            searchEmployeePhone: '',
            inviteDialogShow: false,//是否显示邀请盒子
            isShowScreenTips: false,
            enterpriseStateInfo: '',
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    },
    methods: {
        getDate() {
            this.enterpriseUserList(this.paging, this.pagingSize)
        },
        // 企业用户列表接口
        enterpriseUserList(page = this.page, size = this.size) {
            let that = this;
            that.$http.enterpriseUserList({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size,
                ecardName: that.searchValue,
                userPermission: that.userPermission
            }).then(res => {
                this.UserList = res.data.records
                this.total = res.data.total
                this.currentPage = res.data.current
            })
        },
        // 搜索企业
        search() {
            this.UserList = []
            this.enterpriseUserList()
        },
        // 提交按钮
        submitForm(data) {
            this.userPermission = data
            this.enterpriseUserList()
            this.$common.message('筛选成功！', 'success')
            this.drawer = false
            this.isShowScreenTips = !this.objectValueAllEmpty(data)
        },
        // 判断对象的值是不是全为空
        objectValueAllEmpty(object) {
            let isEmpty = true;
            Object.keys(object).forEach(function (x) {
                if (object[x] != null && object[x] != "") {
                    isEmpty = false;
                }
            });
            if (isEmpty) {//值全为空
                return true;
            }
            return false;
        },

        // 重置按钮
        resetForm(data) {
            this.userPermission = data
        },
        // 关闭添加员工盒子
        closeInviteDialog(data) {
            this.inviteDialogShow = data
        },
        // 关闭从右弹出的抽屉
        closeDrawer(data) {
            this.drawer = data
        }

    },
}

</script>
<style lang='less' scoped>
@import '@/assets/css/JumpListItem.less';

.EmployeeList {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .feature {
            margin-bottom: 20px;

            .search_input {
                input {
                    background-color: #FFF;
                    border-radius: 4px 0 0 4px;
                    border: 1px solid #E0E1E3;
                    box-sizing: border-box;
                    color: #606266;
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    outline: 0;
                    padding: 0 20px;
                    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                    width: 400px;
                    border-right: none;
                    font-size: 16px;
                }

                .search_icon {
                    width: 80px;
                    height: 50px;
                    border-top-right-radius: 4px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-right-radius: 4px !important;
                    border-bottom-left-radius: 0px !important;
                    color: #FFFFFF;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                }
            }

            .Btnbox {
                .addBtn {
                    font-size: 18px;
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                    cursor: pointer;
                    i {
                        margin-right: 10px;
                    }
                }

                .screenBtn {
                    margin-left: 20px;
                    font-size: 18px;
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                    cursor: pointer;

                    i {
                        margin-right: 10px;
                    }
                }
            }

        }

        .customerTotal {
            margin-bottom: 20px;
        }

        .list {
            .item {
                width: 440px;
                height: 100px;
                background: #F8F8F8;
                border-radius: 10px;
                margin-bottom: 20px;
                padding: 20px;
                position: relative;

                .userAvatarUrl {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }

                .BusinessOwner {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    position: absolute;
                    right: 0;
                    background: url('../../../assets/supply/BusinessOwner.png') no-repeat;
                }

                .textBox {
                    height: 80px;
                    width: 245px;
                    margin-left: 30px;

                    .name {
                        font-size: 20px;
                        font-weight: 400;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 14px;
                    }

                    .type {
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 24px;
                    }
                }

                .checkbtn {
                    width: 80px;
                    height: 40px;
                    border: 1px solid #1785F6;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #1785F6;
                    cursor: pointer;
                }
            }
        }
    }

    // .input-box {
    //     .add-Employee {
    //         border-radius: 20px;
    //         width: 120px;
    //         height: 40px;
    //         padding: 0;

    //         .el-icon-circle-plus-outline {
    //             font-size: 20px;
    //         }
    //     }

    //     .screen {
    //         width: 120px;
    //         height: 40px;
    //         padding: 0;
    //         border-radius: 20px;

    //         .el-icon-finished {
    //             font-size: 20px;
    //         }
    //     }

    //     .screen:hover {
    //         cursor: pointer;
    //     }
    // }

    // .list {
    //     margin-top: 30px;

    //     .item {
    //         padding: 15px;
    //         border-bottom: 1px solid #ddd;

    //         .item-image {
    //             width: 100px;
    //             height: 100px;
    //             border-radius: 50%;
    //         }

    //         .baseInfo {
    //             margin-left: 30px;

    //             span {
    //                 font-size: 14px;
    //             }

    //             span:first-child {
    //                 font-size: 20px;
    //             }

    //             span:nth-child(2) {
    //                 margin: 20px 0;
    //             }
    //         }

    //         .state {
    //             text-align: right;
    //             color: red;
    //             margin-right: 20px;
    //         }
    //     }

    //     .hover-change();
    // }

}
</style>
