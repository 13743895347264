var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"EmployeeList"},[_c('el-page-header',{attrs:{"content":"员工列表"},on:{"back":_vm.backPrivious}}),_c('div',{staticClass:"f-w-b"},[_c('NavigationBar'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"banner"},[_vm._v(" "+_vm._s(_vm.enterpriseStateInfo.enterpriseName)+" ")]),_c('div',{staticClass:"f-c-b feature"},[_c('div',{staticClass:"search_input"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchValue),expression:"searchValue",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":"请输入员工名称搜索","maxlength":"10"},domProps:{"value":(_vm.searchValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterSearch.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('el-button',{staticClass:"search_icon",attrs:{"icon":"el-icon-search"},on:{"click":_vm.search}})],1),_c('div',{staticClass:"Btnbox f-c-c"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAddBtn == 'W'),expression:"isShowAddBtn == 'W'"}],staticClass:"addBtn f-c-c",on:{"click":function($event){_vm.inviteDialogShow = true}}},[_c('i',{staticClass:"el-icon-circle-plus-outline"}),_vm._v("添加")]),_c('div',{staticClass:"screenBtn",style:({ display: _vm.isShowScreenBtn === 'D' ? 'none' : 'block' }),on:{"click":function($event){_vm.drawer = true}}},[_c('i',{staticClass:"el-icon-finished"}),_c('span',[_vm._v("筛选")])])])]),(_vm.UserList && _vm.UserList.length)?_c('div',{staticClass:"list f-w"},_vm._l((_vm.UserList),function(item,index){return _c('router-link',{key:index,staticClass:"item f-c",style:({ marginRight: (index % 2 == 0) ? '20px' : '0' }),attrs:{"to":{
                    name: 'EmployeeInfo', query: {
                        key: _vm.$UrlEncode.encode(JSON.stringify({
                            id: item.recordeId, type: 'employeeInfo'
                        }))
                    }
                }}},[_c('img',{staticClass:"userAvatarUrl",attrs:{"src":item.userAvatarUrl,"alt":""}}),_c('div',{staticClass:"f-cl-b textBox"},[_c('span',[_vm._v(_vm._s(item.ecardName))]),_c('span',[_vm._v("联系方式："+_vm._s(item.ecardPhone))]),_c('span',[_vm._v("所属部门："+_vm._s(item.ecardNote ? item.ecardNote : '未设置'))])]),(item.dcPermissionFlag == 'Y')?_c('div',{staticClass:"BusinessOwner"},[_vm._v("企业所有者")]):_vm._e()])}),1):_c('el-empty',{attrs:{"description":_vm.searchValue ? '搜索不到该员工！' : '快去添加员工吧！'}})],1)],1),_c('Pagination',{attrs:{"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}}),_c('PowerDrawer',{attrs:{"drawer":_vm.drawer,"companyInfoNoAvailable":true},on:{"submitForm":_vm.submitForm,"resetForm":_vm.resetForm,"closeDrawer":_vm.closeDrawer}}),_c('InviteEmployee',{attrs:{"inviteDialogShow":_vm.inviteDialogShow},on:{"closeInviteDialog":_vm.closeInviteDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }